import React, { FC } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const ghidoni: FC = () => {
  const titleImage = "_DSC1898.jpg"
  return (
    <Layout>
      <div className="single-project">
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/" + titleImage}
            alt="Ghidoni"
          />
        </div>
        <h3 className="single-project-picture-landscape">
          Ghidoni 2022
          <h5 className="single-project-text">
            Degli amici dopo aver acquistato una casa degli anni 90 a Riazzino
            mi hanno espresso la volontà di eseguire un’ampliamento volto a
            soddisfare meglio le esigenze della loro famigia in fase di
            espansione. La casa originale risulta molto geometrica. È formata da
            una pianta quadrata al piano inferiore che si trasforma in “L” nei
            due piani superiori. Inizialmente ho pensato a riprendere questa
            geometria ma non riuscivo ad integrare l’ampliamento che secondo i
            miei gusti non si inseriva mai in modo soddisfacente. Ho pensato
            quindi di cambiare approccio, l’intervento che ho proposto a Marzio
            e Alice è stato quello di fare un contrasto sia geometrico, di
            materiali, che di linguaggio a livello di aperture. La nuova sala da
            pranzo internamente è tutta di legno, esternamente in lamiera
            graffata color antracite. Le aperture sono state fatte più ampie
            possibile. A livello energetico per il nuovo spazio di circa 30mq
            abbiamo deciso di non istallare ne riscaldamenti ne climatizzazioni.
            Grazie alla tecnica di costruzione e ai materiali utilizzati questo
            nuovo spazio si comporta come una casa passiva. La scelta di
            rinunciare al riscaldamento si è rilavata azzeccata.
          </h5>
        </h3>

        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/_DSC1912.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/_DSC1902.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/_DSC1913.jpg"}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/_DSC1918.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/_DSC1916.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/_DSC1920.jpg"}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/_DSC1927.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/_DSC1923.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/_DSC1936.jpg"}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Balerna Ghidoni/_DSC1933.jpg"}
            alt="SingleLast"
          />
        </div>
      </div>
    </Layout>
  )
}

export default ghidoni
